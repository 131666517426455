import { useMemo } from "react";
import { USE_CASE_ITEMS } from "domains/home/constants";
import { UseCaseItem } from "domains/home/interfaces";

export function useUseCaseItems() {
  const items: UseCaseItem[] = useMemo(() => {
    return USE_CASE_ITEMS.map((item) => ({
      id: item.id,
      title: item.title,
      description: item.description,
      knowledgeCenterLink: item.knowledge_center_link,
    }));
  }, []);

  return items;
}
