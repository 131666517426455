import { GuideKeys } from "domains/guide/constants/guides";
import tutorialsFile from "domains/home/constants/tutorials.json";
import useCasesFile from "domains/home/constants/use-cases.json";

export type TutorialsFile = {
  data: {
    id: string;
    title: string;
    guide: GuideKeys;
  }[];
};

export const TUTORIAL_ITEMS = tutorialsFile.data as TutorialsFile["data"];

// ------------------------------------

export type UseCaseFile = {
  data: {
    id: string;
    title: string;
    description: string;
    knowledge_center_link: string;
  }[];
};

export const USE_CASE_ITEMS = useCasesFile.data as UseCaseFile["data"];
