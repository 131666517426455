import { useCallback } from "react";
import { Section, SectionAction } from "domains/home/components/SectionBase";
import ToolCard from "domains/home/components/SectionTools/ToolCard";
import { useToolItems } from "domains/home/hooks/useTools";
import { CarouselNavButton } from "domains/ui/components/Carousel";
import useEmblaCarousel from "embla-carousel-react";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import { Box, Flex } from "@chakra-ui/react";

interface SectionToolsProps {}

export default function SectionTools(_props: SectionToolsProps) {
  const items = useToolItems();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    skipSnaps: true,
    loop: true,
  });

  const scroll = useCallback(
    (direction: "next" | "prev") => {
      if (!emblaApi) return;
      const currentIndex = emblaApi.selectedScrollSnap();
      const slidesInViewCount = emblaApi.slidesInView().length - 1 || 1;
      const delta = Math.round(slidesInViewCount * 0.8);
      const nextIndex =
        direction === "next" ? currentIndex + delta : currentIndex - delta;
      emblaApi.scrollTo(nextIndex);
    },
    [emblaApi]
  );

  return (
    <Section
      title="Creative Tools"
      action={
        <SectionAction
          internalLink="/tools"
          onClick={() => {
            Track(AnalyticsEvents.Home.ClickedViewAllTools);
          }}
        >
          View All
        </SectionAction>
      }
    >
      <Box ref={emblaRef} pos="relative" overflow="hidden" mx={-9} px={9}>
        <Flex
          sx={{
            backfaceVisibility: "hidden",
            touchAction: "pan-y",
          }}
          align="stretch"
          ml={-5}
        >
          {items.map((item) => (
            <Box
              key={item.id}
              pos="relative"
              flex={["0 0 50%", "0 0 33.3%", "0 0 25%", "0 0 20%", "0 0 16.6%"]}
              pl={5}
            >
              <ToolCard {...item} isHome />
            </Box>
          ))}
        </Flex>
        {items.length > 1 && (
          <>
            <CarouselNavButton
              variant="prev"
              onClick={() => scroll("prev")}
              pos="absolute"
              zIndex="docked"
              left={4}
              top="50%"
              transform="translateY(-50%)"
            />
            <CarouselNavButton
              variant="next"
              onClick={() => scroll("next")}
              pos="absolute"
              zIndex="docked"
              right={4}
              top="50%"
              transform="translateY(-50%)"
            />
          </>
        )}
      </Box>
    </Section>
  );
}
