import React, { useCallback, useEffect, useState } from "react";
import { Section } from "domains/home/components/SectionBase";
import UseCaseCard from "domains/home/components/SectionUseCases/UseCaseCard";
import { useUseCaseItems } from "domains/home/hooks/useUseCases";
import { CarouselNavButton } from "domains/ui/components/Carousel";
import useEmblaCarousel from "embla-carousel-react";

import { Box, Center, Flex } from "@chakra-ui/react";

interface SectionUseCasesProps {}

export default function SectionUseCases(_props: SectionUseCasesProps) {
  const items = useUseCaseItems();
  const [isAutoScroll, setIsAutoScroll] = useState<boolean>(true);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    skipSnaps: true,
    loop: true,
    duration: 30,
  });
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // ----------------------------------

  const updateCurrentIndex = useCallback(() => {
    if (!emblaApi) return;
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on("select", updateCurrentIndex);
    return () => {
      emblaApi.off("select", updateCurrentIndex);
    };
  }, [emblaApi, updateCurrentIndex]);

  const scroll = useCallback(
    (direction: "next" | "prev") => {
      if (!emblaApi) return;
      const currentIndex = emblaApi.selectedScrollSnap();
      const nextIndex =
        direction === "next" ? currentIndex + 1 : currentIndex - 1;
      emblaApi.scrollTo(nextIndex);
    },
    [emblaApi]
  );

  const handleContainerMouseEnter = useCallback(() => {
    setIsAutoScroll(false);
  }, [setIsAutoScroll]);

  const handleContainerMouseLeave = useCallback(() => {
    setIsAutoScroll(true);
  }, [setIsAutoScroll]);

  useEffect(() => {
    if (!isAutoScroll) return;
    const interval = setInterval(() => {
      if (!document.hasFocus()) return;
      scroll("next");
    }, 4_000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isAutoScroll, scroll]);

  return (
    <Section title="Use Cases">
      <Box
        ref={emblaRef}
        pos="relative"
        overflow="hidden"
        mx={-9}
        px={9}
        onMouseEnter={handleContainerMouseEnter}
        onMouseLeave={handleContainerMouseLeave}
      >
        <Flex
          sx={{
            backfaceVisibility: "hidden",
            touchAction: "pan-y",
          }}
          align="stretch"
          ml={-5}
        >
          {items.map((item, index) => (
            <UseCaseCard
              {...item}
              key={item.id}
              style={{ opacity: currentIndex === index ? 1 : 0.1 }}
            />
          ))}
        </Flex>

        {items.length > 1 && (
          <>
            <CarouselNavButton
              variant="prev"
              onClick={() => scroll("prev")}
              pos="absolute"
              zIndex="docked"
              left={4}
              top="50%"
              transform="translateY(-50%)"
            />
            <CarouselNavButton
              variant="next"
              onClick={() => scroll("next")}
              pos="absolute"
              zIndex="docked"
              right={4}
              top="50%"
              transform="translateY(-50%)"
            />
          </>
        )}
      </Box>
      <Center gap={1.5} pt={4}>
        {items.map((item, index) => (
          <Box
            key={`indicator-${item.id}`}
            w={5}
            h={1}
            bg={currentIndex === index ? "textPrimary" : "whiteAlpha.300"}
            borderRadius="full"
            cursor="pointer"
            onClick={() => emblaApi?.scrollTo(index)}
          />
        ))}
      </Center>
    </Section>
  );
}
