import React from "react";
import { UseCaseItem } from "domains/home/interfaces";
import cssResponsivePropertiesToArray from "domains/models/utils/cssResponsivePropertiesToArray";
import Button from "domains/ui/components/Button";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Box,
  Grid,
  HStack,
  Image,
  Link,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";

interface UseCaseCardProps extends UseCaseItem {
  style: {
    opacity: number;
  };
}

export default function UseCaseCard({
  id,
  title,
  description,
  knowledgeCenterLink,
  style: { opacity: s },
}: UseCaseCardProps) {
  return (
    <Box
      flex="0 0 100%"
      w="100%"
      pl={5}
      opacity={s}
      transition={"opacity 0.6s ease-in-out"}
    >
      <Link
        _hover={{ textDecoration: "none", opacity: 0.9 }}
        href={knowledgeCenterLink}
        onClick={() => {
          Track(AnalyticsEvents.Home.ClickedUseCase, {
            id,
          });
        }}
        target="_blank"
      >
        <Box w="100%" p={4} bg="backgroundTertiary.500" borderRadius="2xl">
          <HStack
            pos="relative"
            justify={"start"}
            w="100%"
            h="100%"
            spacing={4}
          >
            <Grid gap={4} templateColumns="repeat(2, 1fr)" w="35%">
              {Array(4)
                .fill("")
                .map((_, index) =>
                  index === 3 ? (
                    <VStack
                      key={`${title}-text`}
                      align="start"
                      justify="end"
                      overflow="hidden"
                      w="100%"
                      p={[2, 2, 2, 2, 6]}
                      aspectRatio="1"
                      spacing={[2, 2, 2, 2, 4]}
                    >
                      <VStack align="stretch" spacing={1}>
                        <Text
                          _hover={{ textDecoration: "none" }}
                          size={[
                            "body.bold.sm",
                            "body.bold.sm",
                            "body.bold.sm",
                            "body.bold.md",
                            "body.bold.lg",
                          ]}
                        >
                          {title}
                        </Text>
                        <Text
                          display={["none", "none", "none", "block", "block"]}
                          color="textSecondary"
                          size={cssResponsivePropertiesToArray(
                            "body.xs",
                            "body.sm"
                          )}
                        >
                          {description}
                        </Text>
                      </VStack>
                      <Button
                        size={cssResponsivePropertiesToArray("xxs", "xs")}
                        flexShrink={0}
                      >
                        Learn more
                      </Button>
                    </VStack>
                  ) : (
                    <Image
                      key={`${title}-image-${index}`}
                      w="100%"
                      borderRadius="lg"
                      alt="use case image"
                      aspectRatio="1"
                      fallback={
                        <Skeleton w="100%" borderRadius="lg" aspectRatio="1" />
                      }
                      fit="cover"
                      src={`/home/use-cases/${id}/${index + 1}.webp`}
                    />
                  )
                )}
            </Grid>
            <Image
              pos="absolute"
              right={0}
              w="calc(65% - var(--chakra-space-4))"
              h="100%"
              borderRadius="lg"
              alt="use case main image"
              fallback={
                <Skeleton
                  pos="absolute"
                  right={0}
                  w="calc(65% - var(--chakra-space-4))"
                  h="100%"
                  borderRadius="lg"
                />
              }
              fit="cover"
              src={`/home/use-cases/${id}/main.webp`}
            />
          </HStack>
        </Box>
      </Link>
    </Box>
  );
}
