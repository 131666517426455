import { NextSeo } from "next-seo";
import SectionTools from "domains/home/components/SectionTools";
import SectionTutorials from "domains/home/components/SectionTutorials";
import SectionUseCases from "domains/home/components/SectionUseCases";
import Footer from "domains/ui/components/Footer";
import { useIntl } from "react-intl";

import { VStack } from "@chakra-ui/react";

export default function Home() {
  const intl = useIntl();

  return (
    <>
      <NextSeo
        title={intl.formatMessage({
          id: "pages.index.title",
          defaultMessage: "Home",
        })}
      />

      <VStack
        align="stretch"
        w="100%"
        maxW="1650px"
        mx="auto"
        pt={6}
        pb={20}
        px={9}
        spacing={20}
      >
        <SectionTools />
        <SectionUseCases />
        <SectionTutorials />
      </VStack>

      <Footer />
    </>
  );
}
