import { Section } from "domains/home/components/SectionBase";
import TutorialCard from "domains/home/components/SectionTutorials/TutorialCard";
import { TUTORIAL_ITEMS } from "domains/home/constants";

import { SimpleGrid } from "@chakra-ui/react";

interface SectionTutorialsProps {}

export default function SectionTutorials(_props: SectionTutorialsProps) {
  return (
    <Section title="Tutorials">
      <SimpleGrid columns={5} spacing={6}>
        {TUTORIAL_ITEMS.map((item) => (
          <TutorialCard key={item.id} {...item} />
        ))}
      </SimpleGrid>
    </Section>
  );
}
