import React, { PropsWithChildren } from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";

import { Box, HStack, Text, VStack } from "@chakra-ui/react";

interface SectionProps extends PropsWithChildren {
  title: React.ReactNode;
  action?: React.ReactElement;
}

export function Section({ title, action, children }: SectionProps) {
  return (
    <VStack align="stretch" spacing={4}>
      <HStack align="end" justify="space-between" spacing={2}>
        <Text size="title.md">{title}</Text>

        {action}
      </HStack>

      <Box>{children}</Box>
    </VStack>
  );
}

// ------------------------------------

interface SectionActionProps extends ButtonProps {}

export function SectionAction(props: SectionActionProps) {
  return (
    <Button {...props} variant="underline" textDecoration="none" size="sm" />
  );
}
