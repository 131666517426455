import { useCallback } from "react";
import { useTourGuideContext } from "domains/guide/contexts/TourGuideProvider";
import { TutorialItem } from "domains/home/interfaces";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import { Center, Image, Skeleton, Text, VStack } from "@chakra-ui/react";

interface TutorialCardProps extends TutorialItem {}

export default function TutorialCard({ id, title, guide }: TutorialCardProps) {
  const { forceShowGuide } = useTourGuideContext();

  const handleClick = useCallback(() => {
    Track(AnalyticsEvents.Home.ClickedTutorial, {
      id,
    });
    forceShowGuide(guide);
  }, [forceShowGuide, guide, id]);

  return (
    <VStack
      align="start"
      flex={1}
      gap={3}
      _hover={{ opacity: 0.8 }}
      cursor="pointer"
      onClick={handleClick}
    >
      <Center
        overflow="hidden"
        w="100%"
        borderRadius="lg"
        aspectRatio="277 / 196"
        bgColor="backgroundTertiary.500"
      >
        <Image
          w="100%"
          h="100%"
          alt={title}
          fallback={<Skeleton w="100%" h="100%" />}
          src={`/home/tutorials/${id}.png`}
        />
      </Center>
      <Text textAlign="start" size="body.lg">
        {title}
      </Text>
    </VStack>
  );
}
