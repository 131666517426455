import React from "react";
import Link from "next/link";
import { ToolItem } from "domains/tools/constants";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import { Box, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";

interface ToolCardProps extends ToolItem {
  isHome?: boolean;
}

export default function ToolCard({
  id,
  title,
  subTitle,
  iconId,
  internalLink,
  soon,
  isHome,
}: ToolCardProps) {
  return (
    <VStack
      key={id}
      as={internalLink ? Link : undefined}
      align="stretch"
      justify="space-between"
      overflow="hidden"
      h="100%"
      borderRadius="lg"
      _hover={internalLink ? { opacity: 0.8 } : undefined}
      cursor={internalLink ? "pointer" : "default"}
      bgColor="backgroundTertiary.500"
      href={internalLink}
      onClick={() => {
        Track(
          isHome
            ? AnalyticsEvents.Home.ClickedTool
            : AnalyticsEvents.Tools.ClickedTool,
          {
            tool: id,
          }
        );
      }}
      spacing={2}
    >
      <Box pos="relative" pt="57%">
        <Image
          pos="absolute"
          top={0}
          left={0}
          w="100%"
          h="100%"
          alt="cover image"
          fit="cover"
          src={`/home/tools/${id}.jpg`}
        />
        <Center
          pos={"absolute"}
          bottom={1}
          left={1}
          p={2}
          borderRadius="lg"
          backdropFilter="blur(50px)"
          bgColor="blackAlpha.400"
        >
          <Icon id={iconId} height="16px" width="16px" />
        </Center>
      </Box>
      <VStack align="stretch" h="100%" p={4} spacing={1}>
        <HStack justify="space-between">
          <Text color="textPrimary" size="body.bold.md">
            {title}
          </Text>

          {!!soon && (
            <Text
              flexShrink={0}
              px={1.5}
              py={0.5}
              color="primary.500"
              borderRadius="full"
              bgColor="primarySoft"
              size="body.bold.sm"
            >
              Soon
            </Text>
          )}
        </HStack>
        <Text color="textSecondary" size="body.sm">
          {subTitle}
        </Text>
      </VStack>
    </VStack>
  );
}
