const cssResponsivePropertiesToArray = <T>(
  propertiesOnSmallScreen: T,
  propertiesOnLargeScreen: T
) => {
  return [
    propertiesOnSmallScreen,
    propertiesOnSmallScreen,
    propertiesOnSmallScreen,
    propertiesOnSmallScreen,
    propertiesOnLargeScreen,
  ];
};

export default cssResponsivePropertiesToArray;
